<template>
  <div class="p_plaintext">
    <div class="p_plaintext-title fw">也在网隐私保护政策</div>
    <p class="p_plaintext-right">版本更新日期：2021年10月26日</p>
    <p class="p_plaintext-right">版本生效日期：2021年11月01日</p>
    <p>特别提示</p>
    <p>
      也在网深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，也在网致力于维持您对也在网的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，
      确保安全原则，主体参与原则，公开透明原则。同时也在网承诺，也在网将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      鉴此，也在网依据
      <span class="">《中华人民共和国个人信息保护法》</span>
      、《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T35273-2020）以及其他相关法律法规和技术规范制定本《也在网隐私保护政策》（下称“本政策”）并特别提示您：
    </p>
    <p>
      本政策适用于佛山市蝶与花信息技术有限公司及其关联公司（以下统称“也在网”）的婚恋交友产品和服务，包括但不限于通过也在网网站（http://www.yezai.com）和也在网APP向您所提供的产品和服务。
      如也在网关联公司的产品和服务中使用了也在网提供的产品和服务（例如直接使用也在网账户登录）但未设独立隐私政策的，则本政策同样适用于该部分产品和服务。
    </p>
    <p class="p_plaintext-top fw">
      在使用也在网各项产品和服务前，请您仔细阅读并充分理解本政策，在确认充分理解并同意后使用相关产品和服务，重点内容也在网已采用加粗加黑表示，望您特别关注。您使用或在也在网更新本隐私政策后
      （也在网会及时提示您更新的情况）继续使用也在网产品和服务，即表示您已充分理解并同意本政策(含更新版本)。如果您不同意本隐私政策中的任何条款，请立刻停止访问、使用也在网产品和服务。
    </p>

    <p class="p_plaintext-top">
      请您确保您向也在网提供的个人信息，以及您授权也在网在本隐私政策所述范围内收集、处理、使用、存储、共享、转让或公开披露相关个人信息，不会侵犯他人合法权益。
    </p>
    <p class="p_plaintext-top">
      也在网是一款提供婚恋交友服务的社交产品，为说明也在网会如何收集、使用和存储您的个人信息及您享有何种权利，也在网将通过本政策向您阐述相关事宜，请根据以下索引阅读相应章节：
    </p>
    <p class="p_plaintext-top">一、也在网如何收集和使用您的个人信息</p>
    <p class="p_plaintext-top">二、也在网如何使用 Cookie 和同类技术</p>
    <p class="p_plaintext-top">
      三、也在网如何共享、转让、公开披露您的个人信息
    </p>
    <p class="p_plaintext-top">四、也在网如何保护您的个人信息安全</p>
    <p class="p_plaintext-top">五、您如何管理您的个人信息</p>
    <p class="p_plaintext-top">六、也在网如何存储您的个人信息</p>
    <p class="p_plaintext-top">七、关于未成年人使用也在网服务的说明</p>
    <p class="p_plaintext-top">八、您的个人信息如何在全球范围转移</p>
    <p class="p_plaintext-top">九、本隐私政策如何更新</p>
    <p class="p_plaintext-top">十、如何联系也在网</p>
    <p class="p_plaintext-top fw">一、也在网如何收集和使用您的个人信息</p>
    <p class="p_plaintext-top">
      个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，
      极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，<span
        class="fw"
        >本隐私政策中涉及的个人敏感信息包括：个人身份信息（包括身份证、毕业证、学位证、行驶证、房产证）、网络身份识别信息、
        其他信息（包括个人手机号码、定位信息）。</span
      >
    </p>
    <p class="p_plaintext-top">
      （一）您须授权也在网收集和使用您个人信息的情形
    </p>
    <p class="p_plaintext-top">
      在您使用也在网服务的过程中，也在网会按照如下方式收集和使用您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务以及保障您的账号安全。
    </p>
    <p class="p_plaintext-top">1.注册、登录</p>
    <p>
      （1）当您注册也在网时，您可以通过手机号创建账号，也在网将通过发送短信验证码的方式确认您的身份是否有效；并且您可选择完善相关的网络身份识别信息（昵称、性别、生日、地区、身高、学历、月薪、婚姻状况信息），收集这些信息是为了帮助您完成注册，保护账号安全。<span
        class="fw"
        >收集手机号码信息是为了满足相关法律法规的网络实名制要求。若您不提供这类信息，将导致注册不成功，您将无法正常使用也在网的服务。您还可以根据自身需求选择是否上传头像，如您提供头像的，您的头像将公开显示；该信息属于敏感信息，拒绝提供该信息不影响您正常使用也在网的其他功能。</span
      >
    </p>
    <p>
      （2）基于也在网与通信运营商的合作，当您使用“一键登录”功能时，经过您的明示同意，运营商会将您的手机号码发送给也在网，便于为您提供快捷的注册登录服务。<span
        class="fw"
        >如果拒绝提供手机号将无法使用“一键登录”方式注册登录，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。</span
      >
    </p>
    <p class="fw">
      2.当您使用也在网服务时，为保障软件与服务的安全，也在网会根据您在软件安装及使用中授予的具体权限，在后台收集您所使用的设备信息与日志信息：设备型号、MAC地址、操作系统、唯一设备标识符（IDFA/IMEI/OAID/Android
      ID/OPENUDID/GUID）、登陆IP地址、软件版本号、接入网络的方式、类型和状态、设备加速器、IMSI信息、手机状态、已安装或正在运行的必要的应用/软件列表信息（包括应用/软件来源、应用/软件程序的总体运行情况）、操作日志、服务日志信息（包括您在也在网搜索、点击查看、搜索查询、交易、关注分享、发布的信息、服务故障信息、引荐网址信息）。
    </p>
    <p class="fw">
      请您理解，单独的设备信息、日志信息是无法识别特定自然人身份的信息。除非将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，也在网会将该类个人信息做匿名化、去标识化处理（取得您的授权或法律法规另有规定的情况除外）。拒绝提供该信息无法保障软件与服务的安全，但不影响您正常使用也在网的其他功能。
    </p>
    <p>
      3.
      当您使用也在网推荐、消息、搜索、视频、相亲交友、聊天、个人资料功能时，您上传的照片、发布的各种信息及产生的交互行为会存储在也在网的服务器中，因为存储是实现这一功能所必需的；也在网会以加密的方式存储。除非经您自主选择或遵从相关法律法规要求，也在网不会将其用于该功能以外的其他用途。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="fw">
      4.当您使用实名认证、阿里认证、学位认证、职业认证、财产认证时，也在网将会收集您的手机信息、身份证信息、个人照片、学位信息、职业信息、房产和车产信息，上述信息属于敏感信息，拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="fw">
      5.当您使用个人资料功能时，您可以选择主动提供以下额外信息补全个人资料，以便于也在网向您提供更具针对性的婚恋交友服务：
    </p>
    <p class="p_plaintext_top">
      （1）个人基本资料：性别、年龄、身高、体重、学历、婚姻状况、所在地、月薪、住房情况【已购房（有贷款）、已购房（无贷款）】、购车情况
    </p>
    <p class="p_plaintext_top">
      （2）详细资料：籍贯、户口、血型、民族、宗教信仰、毕业院校、公司类型、职业（医疗、护理）、是否抽烟、是否喝酒、是否有孩子、是否想要孩子、何时结婚
    </p>
    <p class="p_plaintext_top">（3）自我描述：性格、外形、个性标签</p>
    <p class="p_plaintext_top">（4）运动爱好、爱好美食、旅游足迹</p>
    <p class="p_plaintext_top">
      （5）择偶条件：年龄、身高、所在地、学历、居住地、婚姻状况
    </p>
    <p class="fw">
      其中，为了让喜欢您的异性更容易找到您，更了解您，便于您与其他异性聊天互动，您提供的上述个人资料将公开显示。如果拒绝提供该信息，您将可能无法获得相关服务，但不影响您正常使用也在网的其他功能。
    </p>
    <p>
      6.当您使用也在网搜索、推荐、浏览他人资料功能时，也在网会收集您的搜索记录、阅读记录和访问时间。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p>7. 实现也在网的直播功能或服务</p>
    <p>（1）当您使用也在网的直播功能时，也在网将收集以下信息：</p>
    <p class="p_plaintext_left">a）关注、浏览偏好及您的操作、使用行为信息；</p>
    <p class="p_plaintext_left">b）反馈、打赏及您主动提供的信息。</p>
    <p class="p_plaintext_left">
      拒绝提供上述信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p>（2）提供音视频、图文内容发布和直播服务</p>
    <p class="p_plaintext_left">
      您主动开启直播发布音视频时，也在网将收集您发布的音视频，并展示您在个人资料中所完善的头像、昵称、性别、年龄、所在地、属相、星座、个人简介、个人喜好，和发布的音视频信息内容。
    </p>
    <p class="p_plaintext_left fw">
      a）您开启直播时，也在网会请求您授权开启相机、麦克风权限，如果您不同意或拒绝授权提供，将无法使用此功能，但不影响您正常也在网的其他功能。
    </p>
    <p class="p_plaintext_left fw">
      b）您开启直播时，也在网会获取您填写的地区信息，展示在直播交友分类中，用以将您推荐给与您匹配程度更高的用户；若您未填写地理位置信息，也在网将不会展示您的地区，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="p_plaintext_left fw">
      c）您使用发布视频相亲、发布直播评论（发言）或其他需要进行身份认证的功能或服务时，根据中华人民共和国相关法律法规规定，您需要提供真实身份信息（包括真实姓名、身份证号、面部信息），用于完成实名认证。如果您不提供上述信息或信息不真实，将无法使用此功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p>（3）实现互动交流功能或服务</p>
    <p class="p_plaintext_left">
      a）您主动关注您感兴趣的用户账号并与其他账号进行交流互动、进行评论或分享内容时，也在网会收集您关注的账号，并向您优先展示您关注账号所发布的内容，拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="p_plaintext_left">
      b）您主动使用虚拟财产向其他用户进行打赏时，也在网将收集您的头像、昵称及打赏的数据，并向其他用户进行展示。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="p_plaintext_left">
      c）您主动使用连麦服务，即您申请或者接受相亲房间房主邀请与其进行连麦时，也在网将收集连麦时的音视频内容和发言，并展示您在个人资料中所完善的头像、昵称、性别、年龄、所在地、属相、星座、个人简介、个人喜好以及连麦时的音视频及发言。拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p>
      •
      当您在也在网开通付费会员服务时，您可以选择与也在网合作的第三方支付机构（包括微信、支付宝、苹果支付及银联、网联等支付渠道）所提供的支付服务。
      <span class="fw"
        >在支付过程中，也在网可能会收集您的第三方支付渠道，需要将您的订单号和交易金额信息与这些支付机构共享，以实现其确认您的支付指令并完成支付。</span
      >
      上述信息为也在网向您提供付费服务所必须的信息，也在网无法仅根据该信息获得您的个人财产信息，也不能识别特定自然人身份信息。
    </p>
    <p class="fw">
      （二）您可自主选择是否授权也在网开启以下权限，并收集和使用相应信息
    </p>
    <p class="fw">
      为向您提供更便捷、更优质的产品和服务，也在网在向您提供的以下拓展功能中可能会收集和使用您的个人信息。如果您不提供这些信息，您依然可以正常使用也在网基本的婚恋交友功能，但您可能无法获得这些拓展功能给您带来的用户体验。这些拓展功能包括：
    </p>
    <p class="fw">
      1.基于位置信息的拓展功能：当您使用也在网的推荐、搜索、附近的人、附近的动态功能时，也在网会在您开启位置权限后，访问获取您的地理位置信息，目的是为了向您提供该服务，更好地向您推荐或匹配附近的人，为您就近寻缘。当您使用也在网的发布动态功能时，也在网会在您开启位置权限后，访问获取您的地理位置信息，您可以选择是否标记位置信息展示在您的动态中。位置信息属于敏感信息，拒绝提供该信息仅会使您无法使用上述功能，但不影响您正常使用也在网的其他功能。
    </p>
    <p class="fw">
      2.基于摄像头（相机）的拓展功能：您可在开启摄像头（相机）权限后进行拍照、视频拍摄、视频聊天、视频相亲交友以及特定场景下经您授权的人脸识别功能。
    </p>
    <p class="fw">
      3.基于麦克风的语音技术相关拓展功能：您可以在开启麦克风权限后使用麦克风来进行语音聊天、语音独白录制、语音相亲交友或与也在网的客服机器人或人工客服进行咨询和互动。
    </p>
    <p class="fw">4. 基于照片及文件的读取和写入的拓展功能：</p>
    <p class="fw">
      （1）基于相册（图片库/视频库）的图片/视频访问及上传的拓展功能：您可在开启相册及文件权限后使用该功能上传您的照片/图片/视频，以实现更换头像、个人形象照片、上传认证信息、发表评论、分享或与客服沟通提供证明的功能。
    </p>
    <p class="fw">
      （2）基于语音消息文件的服务：您可在开启相册及文件权限后，使用该功能实现语音消息收取和发送。
    </p>
    <p class="fw">
      5.基于读取电话状态的扩展功能：当您运行也在网时，也在网会向您申请获取此权限，使用场景与目的是读取设备通话状态和识别码，识别设备ID，以便完成安全风控、进行统计和服务推送、消息推送。请您放心该权限无法监听、获取您的任何通话内容与信息。
    </p>
    <p>
      <span class="fw"
        >•基于日历权限的扩展功能：当您使用也在网视频约会服务时，也在网会向您申请获取此权限，以便您设置重要事项提醒，如参与线上视频约会。若您不开启此权限，您将无法使用日历管理相关的功能，但不影响您使用其他服务。</span
      >
      上述拓展功能需要您在您的设备中向也在网开启您的位置信息（地理位置）、摄像头（相机）、相册（图片库/视频库）、麦克风、电话状态以及日历的访问权限，以实现这些功能所涉及的信息的收集和使用。
      在您使用上述功能时，会弹出授权窗口，询问您是否开启此权限，如果您不同意开启，则相应功能无法使用；您也可以通过“手机设置→应用管理→权限管理/手机设置→隐私”中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭（也在网会指引您在您的设备系统中完成设置）。
      <span class="fw">
        请您注意，您开启这些权限即代表您授权也在网可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则也在网将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。
        您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
      </span>
    </p>
    <p class="fw">（三）也在网可能通过间接获得方式收集您的个人信息</p>
    <p>
      您可通过也在网账号在也在网提供的链接入口使用也在网关联公司提供的产品和服务。当您通过也在网账号使用上述服务时，您授权也在网根据实际业务及合作需要，从也在网关联公司处接收、汇总、分析也在网确认其来源合法或您授权同意其向也在网提供的您的个人信息或交易信息。
    </p>
    <p>
      如您拒绝提供上述信息或拒绝授权，可能无法使用也在网关联公司的相应产品和服务，或者无法展示相关信息，但不影响您正常使用使用也在网的其他功能和服务。
    </p>
    <p class="fw">
      （四）征得授权同意的例外您充分知晓，根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
    </p>
    <p class="fw">1、与国家安全、国防安全有关的；</p>
    <p class="fw">2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p class="fw">3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p class="fw">
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p class="fw">5、所收集的个人信息是您自行向社会公众公开的；</p>
    <p class="fw">
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p class="fw">7、根据您的要求签订和履行合同所必需的；</p>
    <p class="fw">
      8、用于维护也在网所提供的产品和服务的安全稳定运行所必需的，例如发现、处置产品和服务的故障；
    </p>
    <p class="fw">9、为合法的新闻报道所必需的；</p>
    <p class="fw">
      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p class="fw">11、法律法规规定的其他情形。</p>
    <p class="fw">（五）也在网收集和使用您的个人信息的其他规则</p>
    <p class="fw">
      1.也在网会根据您的设备信息，通过系统向您展示个性化的第三方推广信息。为保障您接收信息的自主性，当您对也在网推送的信息不感兴趣或希望减少某些信息推荐时，APP端您可以通过以下路径关闭个性化推荐：个人中心>设置>关于软件>隐私保护政策>个性化推荐设置；关闭后，部分页面展示的广告内容如未更新，请选择手动刷新或退出后台重新进入或者重新登录。
    </p>
    <p>
      2.当您使用也在网与其他软件或硬件互通功能时，其他软件或硬件的提供方在经您同意后可以获取您在也在网主动公开或传输的相关信息，您在选择前应充分了解其他软件或硬件的产品功能及信息保护策略。
    </p>
    <p class="fw">
      3.请您理解并同意，为给您带来更好的产品和服务体验，也在网在持续努力改进相关技术，随之也在网可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，也在网将通过更新本政策、弹窗、页面提示、交互流程、网站公告等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您同意后收集、使用。
    </p>
    <p class="fw">
      4.原则上，也在网会根据本政策的约定并为实现也在网的产品和服务功能对所收集的个人信息进行使用。
    </p>
    <p>
      5.为了确保服务的安全，帮助也在网更好地了解也在网应用程序的运行情况，也在网可能记录相关信息：您使用应用程序的频率、崩溃数据、总体使用情况、性能数据以及应用程序的来源。也在网不会将也在网存储在分析软件中的信息与您在应用程序中提供的任何个人身份信息相结合。
    </p>
    <p class="fw">
      6.在收集您的个人信息后，也在网将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下也在网有权使用已经去标识化的信息；并在不透露您个人信息的前提下，也在网有权对用户数据库进行分析并予以商业化的利用。
    </p>
    <p class="fw">
      7.请您注意，您在使用也在网的产品和服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝也在网收集，否则将在您使用也在网的产品和服务期间持续授权也在网使用。在您注销账号时，也在网将停止使用并删除您的个人信息。
    </p>
    <p class="fw">
      8.也在网会对也在网产品和服务的使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示也在网的产品和服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
    </p>
    <p>
      9.若也在网使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，也在网将以合理的方式向您告知，并在使用前再次征得您的同意。
    </p>
    <p class="fw">二、也在网如何使用Cookie和同类技术</p>
    <p>（一）Cookie</p>
    <p>
      为确保网站正常运转、为您获得更轻松的访问体验，也在网会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。
      <span class="fw"
        >也在网向您发送Cookies是为了帮助您在后续访问时调用信息、简化您重复登录的步骤，获得更轻松的访问体验；帮助判断您的登录状态以及保障产品与服务的安全、高效运转。</span
      >
    </p>
    <p>
      也在网不会将Cookies用于本政策所述目的之外的任何用途。
      <span class="fw">您可根据自己的偏好管理或删除Cookies。</span>
      您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问也在网时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
    </p>
    <p class="fw">（二）Cookie 同类技术除</p>
    <p>
      Cookie外，也在网还会在网站上使用网站信标、像素标签等其他同类技术。例如，也在网向您发送的电子邮件可能含有链接至也在网网站内容的地址链接，如果您点击该链接，也在网则会跟踪此次点击。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，也在网能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从也在网的寄信名单中退订。
    </p>
    <p class="fw">三、也在网如何共享、转让、公开披露您的个人信息</p>
    <p class="fw">（一）共享</p>
    <p class="fw">
      1.也在网不会与也在网的产品和服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p class="fw">
      （1）事先获得您的明确授权或同意：获得您的明确同意后，也在网会与其他方共享您的个人信息。
    </p>
    <p class="fw">
      （2）在法定情形下的共享：根据适用的法律法规、法律程序、政府的强制命令或司法裁定而需共享您的个人信息；
    </p>
    <p class="fw">
      （3）在法律要求或允许的范围内，为了保护也在网、也在网的关联方或合作伙伴、您或其他也在网会员或社会公众的利益、财产或安全免遭损害而有必要提供；
    </p>
    <p class="fw">
      （4）只有共享您的个人信息，才能实现也在网的产品与/或服务的核心功能或提供您需要的服务；
    </p>
    <p class="fw">（5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p class="fw">
      （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
    </p>
    <p class="fw">（7） 基于学术研究而使用；</p>
    <p class="fw">（8）基于符合法律法规的社会公共利益而使用。</p>
    <p class="fw">
      2.与关联公司间共享：为实现基于也在网向您提供相关产品或服务，便于也在网向您提供更完善的婚恋交友服务、婚介服务，推荐您可能感兴趣的信息，识别会员账号异常，保护您的账户与交易安全，亦保护也在网关联公司或公众的人身财产安全免遭侵害，我们可能会将您的个人信息与我们的关联公司共享。也在网只会共享必要的个人信息，且这种共享受本政策所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
    </p>
    <p class="fw">
      3.与授权合作伙伴共享：为了向您提供更完善、优质的婚恋交友服务、婚介服务，也在网的某些服务可能由授权合作伙伴提供或由也在网和授权合作伙伴共同提供，也在网可能会与合作伙伴共享您的某些个人信息。也在网仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。也在网的合作伙伴无权将共享的个人信息用于与产品和服务无关的其他用途。
    </p>
    <p>目前，也在网的授权合作伙伴包括以下类型：</p>
    <p class="fw">
      （1）也在网线下服务中心。【具体名单详见也在网的公示，（也在网线下门店）也在网对该名单将不时予以更新】。为您提供全面、优质的婚恋交友、婚介服务或您定制的其他相关服务，也在网会向也在网授权的线下服务中心（包括但不限于也在网线下VIP服务中心、等）提供您的必要个人信息。
    </p>
    <p>
      <span class="fw">（2）广告、分析服务类的授权合作伙伴。</span> 除非得到您的许可，否则也在网不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。 <span class="fw">也在网会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，但不会提供您的个人身份信息，或者也在网将这些信息进行汇总，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。</span> 例如，也在网可以告知广告主有多少人看了他们的广告或在看到广告后安装了应用或购买了商品，或者向这些合作伙伴提供不能识别个人身份的统计信息（例如“男性，25-29岁，位于北京”），帮助他们了解其受众或顾客。
    </p>
    <p class="fw">
      （3）供应商、服务提供商和其他合作伙伴。也在网将信息发送给支持也在网业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析也在网服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
    </p>
    <p>
      4.为了使您能够使用也在网所提供的的服务及功能，也在网的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。也在网接入的第三方SDK清单详情请浏览：第三方sdk清单页面
    </p>
    <p>
      也在网会对授权合作伙伴获取有关信息的软件工具开发包（SDK）进行严格的安全检测。对于也在网与之共享个人信息的公司、组织，也在网会与其约定严格的数据保护措施，要求其按照也在网的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p>
      5.您理解并认可：为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护也在网、您或其他也在网用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租或以其它方式披露的个人信息。
    </p>
    <p class="fw">（二）转让</p>
    <p>也在网不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>
      1.在获取明确同意的情况下转让：获得您的明确同意后，也在网会向其他方转让您的个人信息；
    </p>
    <p class="fw">
      2.在涉及合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，也在网会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则也在网将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <p class="fw">（三）公开披露</p>
    <p>
      也在网仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，公开披露您的个人信息：
    </p>
    <p>
      1. 获得您明确同意或基于您的主动选择，也在网可能会公开披露您的个人信息；
    </p>
    <p>
      2.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，也在网可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当也在网收到上述披露信息的请求时，也在网会要求必须出具与之相应的法律文件（如传票或调查函），并对被披露的信息采取符合法律和业界标准的安全防护措施。
    </p>
    <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
    <p>
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p>1.与国家安全、国防安全有关的；</p>
    <p>2. 与公共安全、公共卫生、重大公共利益有关的；</p>
    <p></p>
    <p>
      4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>5.您自行向社会公众公开的个人信息；</p>
    <p>
      6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p class="fw">
      请知悉，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </p>
    <p class="fw">四、也在网如何保护您的个人信息安全</p>
    <p class="fw">（一）技术措施与数据安全措施</p>
    <p>
      1.也在网努力采取各种符合业界标准、合理可行的物理、电子和管理方面的安全措施来保护您的个人信息安全。也在网积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与也在网提供的服务无关的个人信息。
    </p>
    <p>
      2.也在网已采用合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受
      SSL（Secure Socket
      Layer）协议加密保护；使用加密技术提高个人信息的安全性；使用受信赖的保护机制防止个人信息遭到恶意攻击；部署访问控制机制，与信息接触者签署保密协议，尽力确保只有授权人员才可访问个人信息；举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识；建立监控和审计机制等数据安全措施。
    </p>
    <p class="fw">（二）安全事件处置</p>
    <p>
      1.也在网将尽力确保您发送给也在网的任何信息的安全性，但请您理解，由于技术的限制以及在互联网行业可能存在的各种恶意手段，不可能始终保证信息百分之百的安全。您知悉并理解，您接入也在网服务所用的系统和通讯网络，有可能因也在网可控范围外的因素而出现问题。因此，也在网强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息透露给他人等；建议您不要使用非也在网推荐的通信方式发送个人信息，您可以通过也在网的服务建立联系和相互分享。当您通过也在网的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。请注意，您在使用也在网服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发表动态或者在群聊天、动态等公众场合选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用也在网的服务时共享甚至公开分享相关信息。
    </p>
    <p>
      2.为防止安全事故的发生，也在网制定了预警机制和应急预案，并建立了专门的应急响应团队。若不幸发生个人信息安全事件，也在网将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、也在网已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。也在网将及时将事件相关情况以电话、推送通知、邮件、信函、短信等方式告知您，难以逐一告知用户时，也在网会采取合理、有效的方式发布公告。同时，也在网还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。
    </p>
    <p class="fw">五、您如何管理您的个人信息</p>
    <p>
      为了您可以更加便捷地访问、更正、删除您的个人信息，同时保障您改变或撤回对个人信息使用的同意及注销账号的权利，也在网为您提供了相应的操作设置，您可以参考以下指引进行操作。此外，也在网还设置了投诉举报渠道，您的意见将会得到及时的处理。
    </p>
    <p class="fw">（一）访问您的个人信息</p>
    <p>
      您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
    </p>
    <p>
      您的个人资料——网站端您可以登录账号在“个人中心”页面的“完善资料--我的资料”菜单中访问您提交给也在网的所有个人信息；APP端您可以登录账号进入个人资料页执行访问操作。
    </p>
    <p>
      您的账户信息——网站端您可以登录账号在“个人中心”页面的“我的账户”菜单中查看您的消费记录、充值记录、服务订购记录等信息。APP端您可以登录账号进入我的账户页面，查阅我的账户信息，如我的服务、订单、卡券、礼物。
    </p>
    <p>
      如果您无法通过上述路径访问该等个人信息，您可以随时通过也在网客服与也在网取得联系。也在网将在15天内回复您的访问请求。
    </p>
    <p>
      对于您在使用也在网的产品和服务过程中产生的其他个人信息，也在网将根据本条“（七）响应您的上述请求”中的相关安排向您提供。
    </p>
    <p class="fw">（二）更正或补充您的个人信息</p>
    <p>
      如果您需要修改或补充个人信息，可以按照以下页面的内容进行操作：网站端您可以登录账号在“个人中心”页面的“完善资料--我的资料”菜单更正或补充个人信息；APP端您可以通过登录账号进入个人资料页更正或补充个人信息。
    </p>
    <p>
      但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。如您确有必要修改该类信息，请您通过电子邮件、在线客服或系统反馈的方式联系也在网，也在网将尽快审核所涉问题，并由专人验证您的身份后及时予以回复。
    </p>
    <p>
      当您发现也在网处理的关于您的个人信息有错误时，您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
    </p>
    <p class="fw">（三）删除您的个人信息</p>
    <p>
      您可以通过“（一）访问您的个人信息、（二）更正或补充您的个人信息”中列明的方式删除您的部分个人信息。
    </p>
    <p class="fw">在以下情形中，您可以向也在网提出删除个人信息的请求：</p>
    <p>1、如果也在网处理个人信息的行为违反法律法规；</p>
    <p>2、如果也在网收集、使用您的个人信息，却未征得您的明确同意；</p>
    <p>3、如果也在网处理个人信息的行为严重违反了与您的约定；</p>
    <p>4、如果您不再使用也在网的产品和服务，或您主动注销了账号；</p>
    <p>5、如果也在网终止服务及运营。</p>
    <p>
      若也在网决定响应您的删除请求，也在网还将同时尽可能通知从也在网处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。当您或也在网协助您删除相关信息后，因为适用的法律和安全技术限制，也在网可能无法立即从备份系统中删除相应的信息，也在网将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
    </p>
    <p class="fw">（四）个人信息主体注销账户</p>
    <p>
      如果您需要注销您的也在网账号，您可以通过以下方式向也在网提出账号注销申请：
      <span class="fw"
        >网站端——您可以登录账号点击右上角【个人中心】-【设置】-【资料公开与隐藏】，进行自助注销账号；APP端——方式一：
        您可以登陆账号进入个人中心-点击设置“账号与安全”按钮进入到交友状态设置页-点击底部注销账号按钮；方式二：您可以登陆账号进入个人中心-点击右上角设置图标进入设置页-
        点击交友状态设置栏进入交友状态设置页-点击底部注销账号按钮；</span
      >
      此外您可以通过管理员发信或联系热线客服（4008-234521）按3’转人工客服的方式向也在网提出账号注销申请。也在网将在验证您的身份、
      安全状态、设备信息并清理账号中的资产（包括已购买的服务）、确保账户无任何纠纷后，为您提供账号注销服务。
    </p>
    <p class="fw">
      在您主动注销账户之后，也在网将停止为您提供产品和服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。
    </p>
    <p>（五）获取您的个人信息副本</p>
    <p>
      您有权获取您的个人信息副本。如您需要您的个人信息副本，您可以通过本政策文末提供的方式联系也在网，在核实您的身份后，也在网将向您提供您在也在网服务中的个人信息副本（包括个人基本资料、个人身份信息），但法律法规另有规定的除外。
    </p>
    <p>（六）约束信息系统自动决策</p>
    <p>
      在某些业务功能中，也在网可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权通过本政策文末提供的方式联系也在网，并要求作出解释；也在网也将提供适当的救济方式。
    </p>
    <p class="fw">（七）您的其他权利</p>
    <p>1. 投诉举报</p>
    <p>
      网站端——您可以登录账号进入帮助中心投诉页面发起投诉或意见建议，APP端——您可以通过登录账号进入“意见反馈”页面，提出投诉举报；
    </p>
    <p>
      2.访问隐私政策
      您可以在注册页面，或者在登录个人账号【我】-【设置】-【关于软件】查看本隐私政策的全部内容。
    </p>
    <p class="fw">（八）改变您授权同意的范围</p>
    <p class="fw">
      您可以通过删除信息、关闭功能授权关闭设备功能、在也在网网站或APP软件中进行隐私设置等方式改变您授权也在网继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回也在网继续收集您个人信息的全部授权。
    </p>
    <p class="fw">
      请您理解，每个业务功能需要一些基本的个人信息才能得以完成。当您撤回同意后，也在网无法继续为您提供撤回同意或授权所对应的服务，也在网将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p class="fw">（九）响应您的上述请求</p>
    <p class="fw">
      为保障安全，也在网可能会先要求您验证自己的身份，然后再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。对于您的请求，也在网原则上将于15天内做出答复。如您不满意，还可以通过也在网客服发起投诉。
    </p>
    <p>
      对于您合理的请求，也在网原则上不收取费用，但对多次重复、超出合理限度的请求，也在网将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，也在网可能会予以拒绝。
    </p>
    <p>在以下情形中，按照法律法规要求，也在网将无法响应您的请求：</p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和执行判决等有关的；</p>
    <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
    <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
    <p>6、涉及商业秘密的。</p>
    <p class="fw">六、也在网如何存储您的个人信息</p>
    <p class="fw">（一）信息存储的地点</p>
    <p>
      <span class="fw"
        >也在网依照法律法规的规定，将在中华人民共和国境内收集和产生的您的个人信息存储于中华人民共和国境内。</span
      >
      目前，也在网不会将上述信息传输至境外，如果发生数据的跨境传输，也在网会遵循相关国家规定或征得您的授权同意并要求接收方按照也在网的说明、本隐私政策以及其他任何相关的保密和安全措施来处理上述个人信息。
    </p>
    <p class="fw">（二）信息存储时间</p>
    <p>
      也在网会依照个人信息的不同等级存储不同期限，存储期限严格遵守法律及相关法规规定，个人信息的存储时间始终处于合法合理必要期限内。对于超出存储期限的个人信息，也在网会根据适用法律的要求删除您的个人信息或做匿名化处理。
    </p>
    <p class="fw">（三）信息删除和匿名化处理</p>
    <p>
      如也在网因经营不善或其他原因出现停止运营的情况，也在网会立即停止对您个人信息的收集，删除已收集的个人信息。也在网会将此情况在网站上进行公告或以站内信、邮件等其他合理方式逐一传达到各个用户。对已持有的您的个人信息将进行删除或匿名化处理。
    </p>
    <p class="fw">七、关于未成年人使用也在网服务的说明</p>
    <p class="fw">
      只有年满18周岁的成年单身人士才可以注册也在网。也在网不会向未成年人推销自己，或者未经父母同意而有意收集其未成年子女的个人信息。
    </p>
    <p class="fw">八、您的个人信息如何在全球范围转移</p>
    <p>
      也在网在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
    </p>
    <p>1、法律法规有明确规定；</p>
    <p>2、获得您的明确授权；</p>
    <p>3、您通过互联网进行跨境直播、发布动态等个人主动行为。</p>
    <p>
      针对以上情形，也在网会确保依据本隐私政策对您的个人信息提供足够的保护。
    </p>
    <p class="fw">九、本隐私政策如何更新</p>
    <p>
      （一）本政策为《也在网会员服务条款》的重要组成部分。为给您提供更好的服务以及随着也在网业务的发展，本隐私政策也会随之更新。<span
        class="fw"
        >未经您明确同意，也在网不会限制您按照本隐私政策所应享有的权利。也在网会通过在网站、APP上发出更新版本或以其他适当方式提醒您相关内容的更新，也请您访问也在网以便及时了解最新的隐私保护政策。</span
      >
    </p>
    <p>
      （二）对于重大变更，视具体情况也在网可能还会提供显著的通知（包括也在网会通过也在网公示的方式进行通知甚至向您提供弹窗提示）说明本政策的具体变更内容。
    </p>
    <p>本政策所指的重大变更包括但不限于：</p>
    <p>
      1、也在网的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p>2、个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>3、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      4、也在网负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>5、个人信息安全影响评估报告表明存在高风险时。</p>
    <p class="fw">
      （三）若您不同意修改后的隐私政策，您有权并应立即停止使用也在网的服务。如果您继续使用也在网的服务，则视为您接受也在网对本政策相关条款所做的修改。
    </p>
    <p class="fw">十、如何联系也在网</p>
    <p class="fw">
      （一）如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过访问http://www.yezai.com在线客服或微信客服系统（微信公众号：也在网客服）或拨打也在网的客服电话（4008-234521服务时间：9：00-21：00）等多种方式与也在网联系。
    </p>
    <p>
      <span class="fw">（二）也在网设立了个人信息保护专职部门,</span>
      您也可以将您的问题发送至yezaicom@163.com或寄到如下地址：
    </p>
    <p>名称：佛山市蝶与花信息技术有限公司</p>
    <p>地址：佛山市禅城区季华五路卓远国际商务大厦八层805 法务部（收）</p>
    <p>邮编：528000</p>
    <p>
      <span class="fw">（三）也在网原则上将在15天内回复您的请求。</span>
      如果您对也在网的回复不满意，特别是您认为也在网的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
    </p>
    <p class="p_plaintext-right fs18">也在网</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "也在网-个人信息保护政策",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
    ],
  },
};
</script>

<style lang="less" scoped>
.p_plaintext {
  padding: 0.5rem;
  // font-size: 20px;
  font-size: 20px;
  // font-size: 0.3733rem;
  // line-height: 1.6;
  line-height: 30px;
  text-indent: 2em;
  text-align: justify;
  color: #666;
  p {
    margin-top: 5px;
    margin-top: 15px;
  }
  .p_plaintext-title {
    text-align: center;
    // font-size: 18px;
    font-size: 30px;
    margin-top: 18px;
  }
  .p_plaintext-right {
    text-align: right;
  }
  .p_plaintext-top {
    margin-top: 15px;
  }
  .p_plaintext_top {
    margin-top: 0px;
  }
  .p_plaintext_left {
    margin-left: 30px;
  }
  .fs18 {
    font-size: 28px;
  }
  .fw {
    font-weight: bold;
  }
  .fc {
    color: #000;
  }
}
</style>